<template>
  <section>
    <v-card class="custom-border border pa-2">
      <v-card-text>
        <div class=" d-flex justify-space-between align-center f18 secondary-1--text mb-5" :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'">
          <span class="poppins fw600 mr-auto">Published Courses</span>
        </div>
        <div>
          <section class="d-flex justify-end align-center ml-auto flex-wrap my-1">
            <v-text-field
              outlined
              dense
              flat
              style="width:0px"
              class="general-custom-field f12"
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              v-model="search"
            />
            <v-spacer></v-spacer>
            <section style="width:300px">
              <FormSelectObject
                :placeholder="'Category'"
                :items="categories"
                :value.sync="filter2.courses"
                :class="'ma-1'"
                :loading="loading1"
              />
            </section>
            <section style="width:100px">
              <FormSelectArray
                :placeholder="'Sort'"
                :items="SORT_BY"
                :value.sync="filter2.course_name_sort"
              />
            </section>
          </section>
        </div>
      <v-progress-linear
          indeterminate
          color="primary"
          rounded
          height="3px"
          v-if="loading2"
          class="my-3"
        ></v-progress-linear>
        <div v-if="other_courses.length === 0 && !loading" class="poppins fw600 f13 secondary-4--text font-italic"> No courses available. </div>
        <!-- <section v-if="gallery" class="d-flex flex-wrap">
            <GalleryCards v-for="course in other_courses" :key="course.id" :course="course" class="ma-2"/>
        </section> -->
        <section v-if="gallery" class=" tw-grid tw-grid-cols-4 tw-gap-2">
          <GalleryCards v-for="course in other_courses" :key="course.id" :course="course"/>
        </section>
        <!-- <section v-if="!gallery" class="d-flex flex-column justify-center'">
            <ListCards v-for="course in other_courses" :key="course.id" :course="course" class="my-2"/>
        </section> -->
      </v-card-text>
      <FormPagination 
          :pageCount="pageCount" 
          :page="page"
          :paginate="paginate"
          @page="(e) => { page = e, filterOtherCourses() }" 
          @paginate="(e) => { paginate = e, filterOtherCourses() }"/>
    </v-card>
  </section>
</template>


<style>
.v-slide-group__prev, .v-slide-group__next {
  min-width: auto !important;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import CardGallery from '../../components/user/course/CardGallery.vue';
import CardList from '../../components/user/course/CardList.vue';
import CourseCard from '../../components/user/course/Card.vue'
import GalleryCards from '../../components/user/course/GalleryCard.vue'
import ListCards from '../../components/user/course/ListCard.vue'

export default {
  components: {
    CourseCard,
    CardGallery,
    CardList,
    GalleryCards,
    ListCards
  },
  data: () => ({
    loading: false,
    loading1: false,
    loading2: false,
    gallery: true,
    search: '',
    categories: [
      { text: 'All Categories', value: ''},
      { text: 'Junior Level', value: 'Junior'},
      { text: 'Intermediate Level', value: 'Intermediate'},
      { text: 'Senior Level', value: 'Senior'},
    ],
    SORT_BY: [
				{ text: 'A to Z', value: 'ASC' },
				{ text: 'Z to A', value: 'DESC' },
    ],
    filter1: {
      classes: [],
      course_name_sort: ''
    },
    filter2: {
      courses: '',
      course_name_sort: '',
      price_min: '',
      price_max: ''
    },
    pageCount: 1,
    paginate: '10',
    page: 1,
  }),
  computed: {
    ...mapState({
      other_courses: (state) => state.other_courses,
      tenant: (state) => state.tenant,
    }),
    ...mapState('usr', {
      courses: (state) => state.courses,
      cart: (state) => state.cart,
      classes: (state) => state.classes
    }),
  },

  mounted() {
    /* window.addEventListener('resize', this.onResize, {passive: true})
    this.onResize() */
    /* this.getCourses() */
    this.filterOtherCourses()
    // this.getClassCategory()
  },

  methods: {
    ...mapActions('usr', ['getClassCategoryAction', 'getCoursesAction']),
    ...mapActions(["otherAllCoursesAction", 'otherCoursesAction']),

    getProgress(item){
      let progress = Math.round(((item.total_done_assessments + item.total_done_topics + item.total_done_sub_topics) / (item.assessments_count + item.topics_count + item.sub_topics_count)) * 100)
      
      if(progress >= 100) {
        progress = 100
      }
      
      return progress ? progress : 0
    },

    getCourses() {
      this.loading = true
      this.getCoursesAction(this.filter1).finally(() => {
        this.loading = false
      })
    },

    // getClassCategory() {
    //   this.loading1 = true
    //   this.otherCoursesAction().then(res => {
    //     res.forEach((item) => {
    //       if(item.course_id == null){
    //         this.categories.push({
    //         text: item.title,
    //         value: item.id,
    //       })
    //       }
          
    //     })
    //   }).finally(() => {
    //     this.loading1 = false
    //   })
    // },

    filterOtherCourses() {
      this.loading2 = true
      this.otherAllCoursesAction({...this.filter2, search: this.search, page: this.page, paginate: Number(this.paginate)}).then((res) => {
        this.pageCount = res.last_page
        this.paginate = String(res.per_page)
        this.page = res.current_page
      }).finally(() => {
        this.loading2 = false
      })
    },

    /* onResize(){
      if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
        this.gallery = false
      }
    } */
  },

  watch: {
    filter1: {
      handler(val) {
        this.getCourses()
      },
      deep: true
    },
    filter2: {
      handler(val) {
        this.filterOtherCourses()
      },
      deep: true
    },
    search(val){
      this.filterOtherCourses()
    },
  }
}
</script>